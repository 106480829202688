import React from 'react';
import DietaryQuestion from './DietaryQuestion';
import DeclineQuestion from './DeclineQuestion';

class ResponseQuestion extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
        const { id } = this.props;
        const section = document.getElementById(`section${id}`);
        section.addEventListener('click', this.props.handleSection);
    }

    render() {
        const { id, section, showValidity, rsvpResponses } = this.props;
        const notes = { id, rsvpResponses };
        return(
            <div className="section">
                <p id={`section${id}`} className={ section === `section${id}` ? 'collapsible active' : 'collapsible' } onClick={this.props.handleSection}>Responses for Guest {id}</p>
                <div className="content" style={{ display: section === `section${id}` ? 'block' : 'none'}}>
                    <div className="RSVP-textfields">
                        <div className="RSVP-text">
                            <label htmlFor={`firstNameGuest${id}`} className="RSVP-label">First Name</label>
                            <input id={`firstNameGuest${id}`} className={(rsvpResponses.firstName[id-1] === "" || rsvpResponses.firstName[id-1] === undefined) && showValidity ? 'RSVP-input validation' : 'RSVP-input'} type="text" name="firstName" defaultValue={rsvpResponses.firstName[id-1]} onChange={this.props.handleResponse('firstName', id)} required/>
                        </div>
                        <div className="RSVP-text">
                            <label htmlFor={`lastNameGuest${id}`} className="RSVP-label">Last Name</label>
                            <input id={`lastNameGuest${id}`} className={(rsvpResponses.lastName[id-1] === "" || rsvpResponses.lastName[id-1] === undefined) && showValidity ? 'RSVP-input validation' : 'RSVP-input'} type="text" name="lastName" defaultValue={rsvpResponses.lastName[id-1]} onChange={this.props.handleResponse('lastName', id)} required/>
                        </div>
                    </div>
                    <fieldset className="RSVP-groups">
                        <legend>Will you be able to join us at our wedding?</legend>
                        <div className="RSVP-options">
                            <label htmlFor={`rsvpChoice1Guest${id}`}>Accept</label>
                            <input id={`rsvpChoice1Guest${id}`} className={(rsvpResponses.rsvp[id-1] === "" || rsvpResponses.rsvp[id-1] === undefined) && showValidity ? 'validation' : ""} type="radio" name={`rsvp${id}`} value="going" onChange={this.props.handleResponse('rsvp', id)} defaultChecked={rsvpResponses.rsvp[id-1] === "going"} required/>
                            <label htmlFor={`rsvpChoice2Guest${id}`}>Decline</label>
                            <input id={`rsvpChoice2Guest${id}`} className={(rsvpResponses.rsvp[id-1] === "" || rsvpResponses.rsvp[id-1] === undefined) && showValidity ? 'validation' : ""} type="radio" name={`rsvp${id}`} value="not going" onChange={this.props.handleResponse('rsvp', id)}  defaultChecked={rsvpResponses.rsvp[id-1] === "not going"} required/>
                        </div>
                    </fieldset>
                    {rsvpResponses.rsvp[id-1] === "going" && <DietaryQuestion notes={notes} handleResponse={this.props.handleResponse}/>}
                    {rsvpResponses.rsvp[id-1] === "not going" && <DeclineQuestion notes={notes} handleResponse={this.props.handleResponse}/>}
                </div>
            </div>
        )
    }
}

export default ResponseQuestion;