import React from 'react';

class Banner extends React.Component {
    state = {
        showBanner: true
    }

    handleClose = event => {
        event.preventDefault();
        this.setState({
            showBanner: false
        })
    }

    render() {
        const { showBanner } = this.state;
        const { message } = this.props;
        return(
            <div className="Banner" style={{ display: showBanner ? 'flex' : 'none' }}>
                <p>{message}</p>
                <span className="Banner-close" onClick={this.handleClose}>&times;</span>
            </div>
        )
    }
}

export default Banner;