import React from 'react';
import { NavLink } from 'react-router-dom';

function NotFound() {
    return(
        <div id="message">
            <h2>404</h2>
            <h1>Page Not Found</h1>
            <img src="https://http.cat/status/404.jpg" alt="404 cat"/>
            <p>The specified file was not found on this website. Please check the URL for mistakes and try again.</p>
            <NavLink to="/" className="home-button"><p>Back to Home page</p></NavLink>
        </div>
    )
}

export default NotFound;