import React from 'react';
import Login from './auth/Login';
import { authService } from '../services/authService';

class ProtectedRoute extends React.Component {
    render() {
        const { children, regex } = this.props;
        return (
            <>
                {authService.isAuthenticated() && <>{children}</>}
                {!authService.isAuthenticated() && <Login handleSignIn={this.props.handleSignIn} handleSignUp={this.props.handleSignUp} regex={regex}/>}
            </>
        )
    }
}

export default ProtectedRoute;