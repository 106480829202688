import React from 'react';
import venuePhoto from '../images/de-seversky-mansion-photo.jpg';
import Navbar from './components/Navbar';
import TravelInfo from './components/TravelInfo';

class Travel extends React.Component {
    componentDidMount() {
        const { handleInfoData } = this.props;
        handleInfoData('travel');
    }

    render() {
        const { travelData } = this.props;
        return (
            <div className="App">
                <Navbar/>
                <h1 className="App-section-title">Travel</h1>
                <img src={venuePhoto} className="img-center" alt="de Seversky Mansion"/>
                <div className="App-details">
                    {travelData.section.map((subsection, index) => {
                        return(
                            <TravelInfo subsection={subsection.title} info={subsection.info} text={subsection.text} link={subsection.URL} linkText={subsection.linkText} key={index}/>
                        )
                    })}
                    {/* Shuttle Details */}
                    <fieldset className="Travel-info">
                        <legend className="App-details-title">{travelData.travel.shuttleTitle}</legend>
                        <p>{travelData.travel.shuttlePickupFromHotel}</p>
                        <p>{travelData.travel.shuttlePickupFromVenue}</p>
                    </fieldset>
                    {/* Food Details */}
                    <fieldset className="Travel-info">
                        <legend className="App-details-title">{travelData.travel.foodSpotTitle}</legend>
                        <ul>
                            {travelData.foodSpots.map((food, index) => {
                                return(
                                    <li key={index}><a href={food.URL} target="_blank" rel="noopener noreferrer">{food.linkText}</a></li>
                                )
                            })}
                        </ul>
                    </fieldset>
                    {/* Activity Details */}
                    <fieldset>
                        <legend className="App-details-title">{travelData.travel.dateSpotTitle}</legend>
                        <ul>
                            {travelData.dateSpots.map((spot, index) => {
                                return(
                                    <li key={index}><a href={spot.URL} target="_blank" rel="noopener noreferrer">{spot.linkText}</a></li>
                                )
                            })}
                        </ul>
                    </fieldset>
                </div>
            </div>
        )
    }
}

export default Travel;