import { getAuth, onAuthStateChanged, sendPasswordResetEmail, sendSignInLinkToEmail } from 'firebase/auth';
import { website } from './firebase';

export const authService = {
    isAuthenticated: () => {
        const token = localStorage.getItem('token');
        if(!token) {
            return false;
        }
        return true;
    }, signOutUser: () => {
        localStorage.removeItem('token');
    }, checkUserAuth: async () => {
        const auth = getAuth();
        return await new Promise((resolve, reject) => {
            onAuthStateChanged(auth, (user) => {
                resolve(user);
            }, (error) => {
                reject(error);
            })
        })
    }, sendResetPasswordEmail: (email) => {
        const auth = getAuth();
        return sendPasswordResetEmail(auth, email).then(() => {
            return {"message": "Password reset email sent!"};
        }).catch((error) => {
            return {"message": error.message};
        })
    }, sendSignInLinkEmail: (email) => {
        const auth = getAuth();
        const actionCodeSettings = {
            url: website,
            handleCodeInApp: true
          };
        return sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
            return {"message": "Sign in link email sent!"};
        }).catch((error) => {
            return {"message": error.message};
        })
    }
}