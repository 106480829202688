import React from 'react';

class Question extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div className="Question-details">
                <div className="App-tr first">
                    <p><strong>{this.props.question}</strong></p>
                </div>
                <div className="App-tr">
                    <p className="Answer">{this.props.answer}</p>
                </div>
            </div>
        )
    }
}

export default Question;