import React from 'react';
import Navbar from '../components/Navbar';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import { authService } from '../../services/authService';

class Login extends React.Component {
    state = {
        step: 1,
        email: "",
        password: "",
        showValidity: false,
        showValidityMessage: "",
        showResetMessage: false,
        resetMessage: ""
    }

    componentDidMount() {
        const emailField = document.getElementById('email');
        const passwordField = document.getElementById('password');
        emailField.addEventListener('blur', this.handleValidity);
        passwordField.addEventListener('blur', this.handleValidity);
    }

    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
    }

    doubleStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 2
        })
    }

    handleValidity = event => {
        event.preventDefault();
        const { email, password } = this.state;
        const fieldId = event.target.id;
        const field = document.getElementById(fieldId);
        if(fieldId === 'email' && email !== "" && email !== undefined) {
            field.reportValidity();
        } else if(fieldId === 'password' && password !== "" && password !== undefined) {
            field.reportValidity();
        }
    }

    handleChange = (input) => event => {
        this.setState({
            [input]: event.target.value
        })
    }

    handleSubmit = () => {
        const { email, password } = this.state;
        const form = document.getElementById('Login-form');
        if(form.checkValidity()) {
            this.props.handleSignIn(email, password).then((event) => {
                if(event && event.error !== undefined && event.error !== "") {
                    if(event.error === "auth/invalid-credential") {
                        this.setState({
                            showValidity: true,
                            showValidityMessage: "Email or password is incorrect."
                        })
                    } else {
                        const message = event.error.substring(5, event.error.length).replace(/-/g, " ");
                        this.setState({
                            showValidity: true,
                            showValidityMessage: message
                        })
                    }
                }
            })
        } else if(!form.checkValidity()) {
            form.reportValidity();
        }
    }

    handleResetPassword = () => {
        const { email } = this.state;
        const form = document.getElementById('Forgot-form');
        if(form.checkValidity()) {
            authService.sendResetPasswordEmail(email).then((event) => {
                if(event) {
                    this.setState({
                        showResetMessage: true,
                        resetMessage: event.message
                    })
                }
            })
        } else if(!form.checkValidity()) {
            form.reportValidity();
        }
    }

    render() {
        const { step, email, password, showValidity, showValidityMessage, showResetMessage, resetMessage } = this.state;
        const { regex } = this.props;
        return (
            <div className="App">
                <Navbar/>
                {step === 1 && 
                    <div className="App-details">
                        <p className="App-section-title">Login</p>
                        <form id="Login-form" className="Auth-form">
                            <div className="Auth-fields">
                                <label className="Auth-label">Email</label>
                                <input id="email" className="Auth-input" type="email" name="email" defaultValue={email} onChange={this.handleChange('email')} required/>
                            </div>
                            <div className="Auth-fields">
                                <label className="Auth-label">Password</label>
                                <input id="password" className="Auth-input" type="password" name="password" defaultValue={password} onChange={this.handleChange('password')} required/>
                            </div>
                            {showValidity && <p className="subtitle">{showValidityMessage}</p>}
                            <button type="button" className="Auth-submit" onClick={this.handleSubmit}>Login</button>
                        </form>
                        <div className="link-container">
                            <p className="subtitle"><span className="link" onClick={this.nextStep}>Forgot password?</span></p>
                        </div>
                        <div className="link-container">
                            <p className="subtitle">Don't have an account? <span className="link" onClick={this.prevStep}>Sign up</span></p>
                        </div>
                    </div>
                }
                {step === 0 && <Signup nextStep={this.nextStep} handleSignUp={this.props.handleSignUp} regex={regex}/>}
                {step === 2 && 
                    <ForgotPassword handleChange={this.handleChange} handleResetPassword={this.handleResetPassword} prevStep={this.prevStep} email={email} showResetMessage={showResetMessage} resetMessage={resetMessage}/>
                }
            </div>
        )
    }
}

export default Login;