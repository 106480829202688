import React from 'react';

class ForgotPassword extends React.Component {
    render() {
        const { email, showResetMessage, resetMessage } = this.props;
        return (
            <div className="App-details">
                <p className="App-details-title">Forgot Password</p>
                {!showResetMessage && 
                    <form id="Forgot-form" className="Auth-form">
                        <div className="Auth-fields">
                            <label className="Auth-label">Email</label>
                            <input id="email" className="Auth-input" type="email" name="email" defaultValue={email} onChange={this.props.handleChange('email')} required/>
                        </div>
                        <button type="button" className="Auth-submit" onClick={this.props.handleResetPassword}>Reset Password</button>
                    </form>
                }
                {showResetMessage && <div><p>{resetMessage}</p></div>}
                <div className="link-container">
                    <p className="subtitle">Back to <span className="link" onClick={this.props.prevStep}>Login</span></p>
                </div>
            </div>
        )
    }
}

export default ForgotPassword;