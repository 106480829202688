import React from 'react';

class Signup extends React.Component {
    state = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        regex: this.props.regex,
        passwordCheck: false,
        showValidity: false,
        showValidityMessage: "",
        showMessage: false
    }

    componentDidMount() {
        const firstNameField = document.getElementById('firstName');
        const lastNameField = document.getElementById('lastName');
        const emailField = document.getElementById('email');
        const passwordField = document.getElementById('password');
        firstNameField.addEventListener('blur', this.handleValidity);
        lastNameField.addEventListener('blur', this.handleValidity);
        emailField.addEventListener('blur', this.handleValidity);
        passwordField.addEventListener('blur', this.handleValidity);
    }

    handleValidity = event => {
        event.preventDefault();
        const { firstName, lastName, email, password } = this.state;
        const fieldId = event.target.id;
        const field = document.getElementById(fieldId);
        if(fieldId === 'firstName' && firstName !== "" && firstName !== undefined) {
            field.reportValidity();
        } else if(fieldId === 'lastName' && lastName !== "" && lastName !== undefined) {
            field.reportValidity();
        } else if(fieldId === 'email' && email !== "" && email !== undefined) {
            field.reportValidity();
        } else if(fieldId === 'password' && password !== "" && password !== undefined) {
            field.reportValidity();
        }
    }

    handleChange = (input) => event => {
        this.setState({
            [input]: event.target.value
        })
    }

    handleToggle = event => {
        this.setState({
            passwordCheck: event.target.checked
        })
    }

    handleSubmit = () => {
        const { firstName, lastName, email, password } = this.state;
        const location = window.location.href;
        const form = document.getElementById('Signup-form');
        if(form.checkValidity()) {
            this.props.handleSignUp(email, password, firstName, lastName, location).then((event) => {
                if(event && event.error !== undefined && event.error !== "") {
                    if(event.error === "auth/email-already-in-use") {
                        this.setState({
                            showValidity: true,
                            showValidityMessage: "Email is already associated with a user account."
                        })
                    } else {
                        const message = event.error.substring(5, event.error.length).replace(/-/g, " ");
                        this.setState({
                            showValidity: true,
                            showValidityMessage: message
                        })
                    }
                } else {
                    this.setState({
                        showMessage: true
                    })
                }
            })
        } else if(!form.checkValidity()) {
            form.reportValidity();
        }
    }

    render() {
        const { firstName, lastName, email, password, regex, passwordCheck, showValidity, showValidityMessage, showMessage } = this.state;
        return (
            <div className="App-details">
                {!showMessage && 
                    <>
                        <p className="App-section-title">Sign Up</p>
                        <p className="subtitle">Please create only one account per party.</p>
                        <form id="Signup-form" className="Auth-form">
                            <div className="Auth-fields">
                                <label className="Auth-label">First Name</label>
                                <input id="firstName" className="Auth-input" type="text" name="firstName" defaultValue={firstName} onChange={this.handleChange('firstName')} required/>
                            </div>
                            <div className="Auth-fields">
                                <label className="Auth-label">Last Name</label>
                                <input id="lastName" className="Auth-input" type="text" name="lastName" defaultValue={lastName} onChange={this.handleChange('lastName')} required/>
                            </div>
                            <div className="Auth-fields">
                                <label className="Auth-label">Email</label>
                                <input id="email" className="Auth-input" type="email" name="email" defaultValue={email} onChange={this.handleChange('email')} required/>
                            </div>
                            <div className="Auth-fields">
                                <label className="Auth-label">Password</label>
                                <input className="Auth-input" type={passwordCheck ? "text" : "password"} id="password" name="password" defaultValue={password} pattern={regex} title="Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters" onChange={this.handleChange('password')} required/>
                            </div>
                            <div className="Auth-toggle">
                                <input className="Auth-toggle-label" type="checkbox" checked={passwordCheck} onChange={this.handleToggle}/>
                                <label className="Auth-input">Show Password</label>
                            </div>
                            {showValidity && <p className="subtitle">{showValidityMessage}</p>}
                            <button type="button" className="Auth-submit" onClick={this.handleSubmit}>Sign Up</button>
                        </form>
                        <div className="link-container"><p className="subtitle">Already have an account? <span className="link" onClick={this.props.nextStep}>Sign in</span></p></div>
                    </>
                }
                {showMessage && <div><p>Email verification sent! Please go to your email and click on the link to confirm your email address, thanks.</p></div>}
            </div>
        )
    }
}

export default Signup;