import React, { Component } from 'react';
import storyPhoto from '../images/school-library-building.jpg';
import japanPhoto from '../images/first-engagement-photo.jpg';
import Navbar from './components/Navbar';
import Divider from './components/Divider';

class Story extends Component {
    componentDidMount() {
        const { handleInfoData } = this.props;
        handleInfoData('story');
    }

    render() {
        const { storyData } = this.props;
        return (
            <div className="App">
                <Navbar/>
                <h1 className="App-section-title">Story</h1>
                <img src={japanPhoto} className="img-left" alt="engagement in Kyoto"/>
                <div className="App-details">
                    <p>{storyData.story.text}</p>
                    <Divider/>
                    <img src={storyPhoto} className="img-center" alt="school library building"/>
                    <p className="App-section-title">About Us</p>
                    {storyData.aboutUs.map((about, index) => {
                        return (
                            <p key={index}>{about.text} <strong>{about.bold}</strong></p>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default Story;