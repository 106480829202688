import React from 'react';

class ConfirmationQuestion extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    
    createConfirmations = (partySize) => {
        const { rsvpResponses } = this.props;
        const confirmationList = [];
        for(let i = 0; i < partySize; i++) {
            confirmationList.push(
                <fieldset className="RSVP-groups" key={i+1}>
                    <legend>Guest {i+1}: {rsvpResponses.firstName[i]} {rsvpResponses.lastName[i]}</legend>
                    <p>RSVP Status: {rsvpResponses.rsvp[i]}</p>
                    {rsvpResponses.rsvp[i] === "going" && <p>Dietary Notes: {rsvpResponses.dietary[i] === "" || rsvpResponses.dietary[i] === undefined ? "(no response)" : rsvpResponses.dietary[i]}</p>}
                    {rsvpResponses.rsvp[i] === "not going" && <p>Notes: {rsvpResponses.declineNote[i] === "" || rsvpResponses.declineNote[i] === undefined ? "(no response)" : rsvpResponses.declineNote[i]}</p>}
                </fieldset>
            );
        }
        return(confirmationList);
    }

    render() {
        const { rsvpResponses } = this.props;
        return (
            <div className="RSVP-groups">
                <p>Please review the following responses:</p>
                {rsvpResponses.partySize > 0 && this.createConfirmations(rsvpResponses.partySize)}
            </div>
        )
    }
}

export default ConfirmationQuestion;