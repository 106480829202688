import React from 'react';

class ProfileModal extends React.Component {
    render() {
        const { photo, name, title, info, slide } = this.props;
        return (
            <div className="Profile-modal" id={title} style={{ display: slide === title ? 'flex' : 'none' }}>
                <div className="Profile-modal-details">
                    <p className="App-details-title">{name}</p>
                    <p><strong>{title}</strong></p>
                    <p>{info}</p>
                </div>
                <img src={require(`../../images/${photo}`)} className="img-center" alt={photo.title}/>
            </div>
        )
    }
}

export default ProfileModal;