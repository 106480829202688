import React from 'react';

class PartyQuestion extends React.Component {
    render() {
        const { partySize } = this.props;
        return(
            <fieldset className="RSVP-groups">
                <legend>How many people are in your party?</legend>
                <div className="RSVP-fields">
                    <input type="number" className="RSVP-input" name="partySize" defaultValue={partySize} min="1" onChange={this.props.handleParty}/>
                </div>
            </fieldset>
        )
    }
}

export default PartyQuestion;