import React from 'react';

class Profile extends React.Component {
    render() {
        const { photo, name, title } = this.props;
        return (
            <div className="Profile-details">
                <img src={require(`../../images/${photo}`)} className="img-center" alt={photo.title} onClick={this.props.handleOpen} id={title}/>
                <p>{name}</p>
                <p><strong>{title}</strong></p>
            </div>
        )
    }
}

export default Profile;