import React from 'react';
import Navbar from './components/Navbar';
import Profile from './components/Profile';
import ProfileModal from './components/ProfileModal';

class WeddingParty extends React.Component {
    state = {
        showModal: false,
        slide: ""
    }

    componentDidMount() {
        const { handleInfoData } = this.props;
        handleInfoData('weddingParty');
        window.addEventListener('click', this.handleChange);
    }

    handleChange = event => {
        event.preventDefault();
        if(event.target.id === 'modal') {
            this.setState({
                showModal: false
            })
        }
    }

    handleOpen = event => {
        event.preventDefault();
        this.setState({
            showModal: true,
            slide: event.target.id
        })
    }

    handleClose = event => {
        event.preventDefault();
        this.setState({
            showModal: false
        })
    }

    handleSwitch = event => {
        event.preventDefault();
        this.setState({
            slide: event.target.id
        })
    }

    render() {
        const { partyData } = this.props;
        const { showModal, slide } = this.state;
        const party = [];
        return (
            <div className="App">
                <Navbar/>
                <div className="App-details">
                    <h1 className="App-section-title">Wedding Party</h1>
                    <div className="App-table-container">
                        <div className="Party-details">
                            <div className="App-tr first">
                                {partyData.bridespeople.map((brideperson, index) => {
                                    party.push(brideperson);
                                    return(
                                        <Profile photo={brideperson.photo} name={brideperson.name} title={brideperson.title} info={brideperson.bio} handleOpen={this.handleOpen} key={index}/>
                                    )
                                })}
                            </div>
                            <div className="App-tr">
                                {partyData.groomspeople.map((groomperson, index) => {
                                    party.push(groomperson);
                                    return(
                                        <Profile photo={groomperson.photo} name={groomperson.name} title={groomperson.title} info={groomperson.bio} handleOpen={this.handleOpen} key={index}/>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div id="modal" className="Modal" style={{ display: showModal ? 'block' : 'none' }}>
                        <div className="Modal-content">
                            <span className="Modal-close" onClick={this.handleClose}>&times;</span>
                            {party.map((member, index) => {
                                return(
                                    <ProfileModal photo={member.photo} name={member.name} title={member.title} info={member.bio} key={index} slide={slide}/>
                                )
                            })}
                            <div className="Modal-slider">
                                {party.map((member, index) => {
                                    return(
                                        <button onClick={this.handleSwitch} key={index} id={member.title} title={member.title} className={slide === member.title ? 'modal-active' : ''}/>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WeddingParty;