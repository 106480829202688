import React from 'react';

class DietaryQuestion extends React.Component {
    render() {
        const { notes } = this.props;
        return(
            <fieldset className="RSVP-groups">
                <legend>You will be able to choose your entrée on the day of our wedding. Please let us know if you have any dietary restrictions or allergies.</legend>
                <div className="RSVP-fields">
                    <textarea id={`dietaryGuest${notes.id}`} className="RSVP-input" name="dietary" defaultValue={notes.rsvpResponses.dietary[notes.id-1]} onChange={this.props.handleResponse('dietary', notes.id)}/>
                </div>
            </fieldset>
        )
    }
}

export default DietaryQuestion;