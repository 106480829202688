import React from 'react';

class DeclineQuestion extends React.Component {
    render() {
        const { notes } = this.props;
        return(
            <fieldset className="RSVP-groups">
                <legend>Would you like to include a note to the couple?</legend>
                <div className="RSVP-fields">
                    <textarea id={`declineNoteGuest${notes.id}`} className="RSVP-input" name="declineNote" defaultValue={notes.rsvpResponses.declineNote[notes.id-1]} onChange={this.props.handleResponse('declineNote', notes.id)}/>
                </div>
            </fieldset>
        )
    }
}

export default DeclineQuestion;