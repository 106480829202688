import React, { Component } from 'react';
import Navbar from './components/Navbar';
import Form from './components/Form';
import emailjs from '@emailjs/browser';

class Contact extends Component {
    state = {
        showMessage: false,
        serviceId: this.props.emailjs.serviceId,
        templateId: this.props.emailjs.templateId,
        publicKey: this.props.emailjs.publicKey
    }

    componentDidMount () {
        // const script = document.createElement('script');
        // script.src = "https://www.google.com/recaptcha/api.js";
        // script.async = true;
        // script.defer = true;
        // script.setAttribute('id', 'script');
        // document.body.appendChild(script);
    }

    componentWillUnmount() {
        // const script = document.getElementById('script');
        // script.parentNode.removeChild(script);
    }

    sendEmail = (event) => {
        event.preventDefault();
        const {serviceId, templateId, publicKey} = this.state;
        emailjs.sendForm(serviceId, templateId, event.target, {
            publicKey: publicKey
        }).then((result) => {
            console.log("Success", result.text);
            this.setState({
                showMessage: true
            })
        }, (error) => {
            console.log("Failed", error.text);
        })
        event.target.reset();
    }

    render() {
        const  {showMessage } = this.state;
        return (
            <div className="App">
                <Navbar/>
                <div className="App-details">
                    <h1 className="App-section-title">Contact</h1>
                    {!showMessage && <p>Please use this form to contact us or submit any questions/concerns.</p>}
                    {!showMessage && <Form sendEmail={this.sendEmail}/>}
                    {/* {!showMessage && showFailedMessage && <p>Please complete reCAPTCHA before submitting</p>} */}
                    {showMessage && <p>Thank you, message sent!</p>}
                </div>
            </div>
        )
    }
}

export default Contact;