import React, { Component } from 'react';
import { Route, Routes, NavLink } from 'react-router-dom';
import './App.css';
import Home from './routes/Home';
import Story from './routes/Story';
// import Schedule from './routes/Schedule';
import RSVP from './routes/RSVP';
import Travel from './routes/Travel';
import WeddingParty from './routes/WeddingParty';
import Registry from './routes/Registry';
import FAQ from './routes/FAQ';
import Contact from './routes/Contact';
import ProtectedRoute from './routes/ProtectedRoute';
import Banner from './routes/components/Banner';
import Top from './routes/components/Top';
import NotFound from './routes/components/NotFound';
import TBD from './routes/TBD';
// import Map from './routes/Map';
import { weddingDate, emailjs, regex, getInfo, getUser, getParty, signUpUser, signInUser, signOutUserAuth, createInfo, updateInfo } from './services/firebase';
import { authService } from './services/authService';

class App extends Component {
  state = {
    home: {},
    story: {},
    aboutUs: [],
    schedule: {},
    events: [],
    travel: {},
    section: [],
    foodSpots: [],
    dateSpots: [],
    weddingParty: {},
    bridespeople: [],
    groomspeople: [],
    userData: {},
    guestParty: {},
    registry: {},
    guestFAQ: {},
    FAQs: [],
    showBanner: false,
    isSignedIn: false
  }

  componentDidMount() {
    this.handleInfoData('home');
    this.setState({
      isSignedIn: authService.isAuthenticated()
    })
  }

  handleInfoData = async (path) => {
    const data = await getInfo(path);
    this.setState({
      [path]: data
    })

    if(path === 'home') {
      this.setState({
        showBanner: data.showBanner
      })
    } else if(path === 'story') {
      this.setState({
        aboutUs: data.aboutUs
      })
    } else if(path === 'schedule') {
      this.setState({
        events: data.events
      })
    } else if(path === 'travel') {
        this.setState({
          section: data.section,
          foodSpots: data.foodSpots,
          dateSpots: data.dateSpots
        })
    } else if(path === 'weddingParty') {
      this.setState({
        bridespeople: data.bridespeople,
        groomspeople: data.groomspeople
      })
    } else if(path === 'guestFAQ') {
      this.setState({
        FAQs: data.FAQs
      })
    }
  }

  handleUserData = async () => {
    const user = await authService.checkUserAuth();
    if(user) {
      const userData = await getUser(user.uid);
      if(userData && userData.party !== "") {
        const partyData = await getParty(userData.party);
        this.setState({
          userData: userData,
          guestParty: partyData
        })
      } else {
        this.setState({
          userData: userData
        })
      }
    }
  }
  
  handlePartyData = async (path) => {
    const data = await getParty(path);
    this.setState({
      guestParty: data
    })
  }

  handleSignUp = async (email, password, firstName, lastName, location) => {
    const user = await signUpUser(email, password, firstName, lastName, location);
    if(user.error) {
      return user;
    } 
    // else {
    //   this.setState({
    //     isSignedIn: true
    //   })
    // }
  }

  handleSignIn = async (email, password) => {
    const user = await signInUser(email, password);
    if(user.error) {
      return user;
    } else {
      this.setState({
        isSignedIn: true,
        user: user
      })
    }
  }

  handleSignOut = () => {
    signOutUserAuth();
    authService.signOutUser();
    this.setState({
      isSignedIn: false
    })
  }
  
  handleCreateData = (dataObjList) => {
    return createInfo(dataObjList);
  }

  handleUpdateData = (dataObjList) => {
    return updateInfo(dataObjList);
  }

  render() {
    const { home, story, aboutUs, schedule, events, travel, section, foodSpots, dateSpots, weddingParty, bridespeople, groomspeople, userData, guestParty, registry, guestFAQ, FAQs, showBanner, isSignedIn } = this.state;
    const storyData  = { story, aboutUs };
    const scheduleData = { schedule, events };
    const travelData = { travel, section, foodSpots, dateSpots };
    const partyData = { weddingParty, bridespeople, groomspeople };
    const rsvpData = { userData, guestParty };
    const faqData = { guestFAQ, FAQs };
    return (
      <div>
        {showBanner && <Banner message={home.text}/>}
        {isSignedIn && <div className="header"><NavLink onClick={this.handleSignOut}>Logout</NavLink></div>}
        <Routes>
          <Route path="/">
            <Route index element={<Home homeData={home} weddingDate={weddingDate}/>}/>
            <Route path="story" element={<Story handleInfoData={this.handleInfoData} storyData={storyData}/>}/>
            <Route 
              path="schedule" 
              element={
                <ProtectedRoute handleSignIn={this.handleSignIn} handleSignUp={this.handleSignUp} regex={regex}>
                  <TBD handleInfoData={this.handleInfoData} scheduleData={scheduleData}/>
                </ProtectedRoute>}
            />
            <Route 
              path="RSVP" 
              element={
                <ProtectedRoute handleSignIn={this.handleSignIn} handleSignUp={this.handleSignUp} regex={regex}>
                  <RSVP handleUserData={this.handleUserData} handleCreateData={this.handleCreateData} handleUpdateData={this.handleUpdateData} rsvpData={rsvpData}/>
                </ProtectedRoute>}
            />
            <Route 
              path="travel" 
              element={
                <ProtectedRoute handleSignIn={this.handleSignIn} handleSignUp={this.handleSignUp} regex={regex}>
                  <Travel handleInfoData={this.handleInfoData} travelData={travelData}/>
                  </ProtectedRoute>}
            />
            <Route 
              path="weddingparty" 
              element={
                <ProtectedRoute handleSignIn={this.handleSignIn} handleSignUp={this.handleSignUp} regex={regex}>
                  <WeddingParty handleInfoData={this.handleInfoData} partyData={partyData}/>
                </ProtectedRoute>}
            />
            <Route 
              path="registry" 
              element={
                <ProtectedRoute handleSignIn={this.handleSignIn} handleSignUp={this.handleSignUp} regex={regex}>
                  <Registry handleInfoData={this.handleInfoData} registryData={registry}/>
                </ProtectedRoute>}
            />
            <Route 
              path="FAQ" 
              element={
                <ProtectedRoute handleSignIn={this.handleSignIn} handleSignUp={this.handleSignUp} regex={regex}>
                  <FAQ handleInfoData={this.handleInfoData} faqData={faqData}/>
                </ProtectedRoute>}
            />
            <Route path="contact" element={<Contact emailjs={emailjs}/>}/>
            <Route path="*" element={<NotFound/>}/>
            {/* <Route path="map" element={<Map/>}/> */}
          </Route>
        </Routes>
        <Top/>
      </div>
    );
  }
}

export default App;
