import React from 'react';

class TravelInfo extends React.Component {
    render() {
        const { subsection, info, text, link, linkText } = this.props;
        return(
            <fieldset className="Travel-info">
                <legend className="App-details-title">{subsection}</legend>
                <p>{info}</p>
                <p>{text} <a href={link} target="_blank" rel="noopener noreferrer"><span className="underline">{linkText}</span></a></p>
            </fieldset>
        )
    }
}

export default TravelInfo;