import React from 'react';
import Navbar from './components/Navbar';
import Question from './components/Question';

class FAQ extends React.Component {
    componentDidMount() {
        const { handleInfoData } = this.props;
        handleInfoData('guestFAQ');
    }

    render() {
        const { faqData } = this.props;
        return (
            <div className="App">
                <Navbar/>
                <div className="App-details">
                    <h1 className="App-section-title">Guest FAQ</h1>
                    <p>{faqData.guestFAQ.text}</p>
                </div>
                <div className="App-table-container">
                    {faqData.FAQs.map((faq, index) => {
                        return (
                            <Question question={faq.question} answer={faq.answer} key={index}/>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default FAQ;