import React from 'react';
import Navbar from './components/Navbar';

class Registry extends React.Component {
    componentDidMount() {
        const { handleInfoData } = this.props;
        handleInfoData('registry');
    }

    render() {
        const { registryData } = this.props;
        return (
            <div className="App">
                <Navbar/>
                <div className="App-details">
                    <h1 className="App-section-title">Registry</h1>
                    <p>{registryData.info} <a href={registryData.URL} target="_blank" rel="noopener noreferrer"><span className="underline">{registryData.linkText}</span></a> {registryData.text}</p>
                </div>
            </div>
        )
    }
}

export default Registry;