import { initializeApp } from 'firebase/app';
import { getDatabase, onValue, ref, set } from 'firebase/database';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, updateProfile, signOut, onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

export const weddingDate = process.env.REACT_APP_WEDDING_DATE;

export const emailjs = {
    serviceId: process.env.REACT_APP_SERVICE_ID,
    templateId: process.env.REACT_APP_TEMPLATE_ID,
    publicKey: process.env.REACT_APP_PUBLIC_KEY
}

export const regex = process.env.REACT_APP_PASSWORD_REGEX_PATTERN;

export const website = process.env.EACT_APP_CUSTOM_DOMAIN;

export const getInfo = async (path) => {
    try {
        const dbRef = ref(db, `info/${path}`);
        return await new Promise((resolve, reject) => {
            onValue(dbRef, (snapshot) => {
                const data = snapshot.val();
                resolve(data);
            }, (error) => {
                reject(error);
            })
        })
    } catch (err) {
        console.log("Issue fetching snapshot of database", err);
        // throw err;
    }
}

export const getUser = async (uid) => {
    try {
        const dbRef = ref(db, `users/${uid}`);
        return await new Promise((resolve, reject) => {
            onValue(dbRef, (snapshot) => {
                const userData = snapshot.val();
                resolve(userData);
            }, (error) => {
                reject(error);
            })
        })
    } catch (err) {
        console.log("Issue fetching user data from database", err);
        // throw err;
    }
}

export const getParty = async (path) => {
    try {
        const dbRef = ref(db, `parties/${path}`);
        return await new Promise((resolve, reject) => {
            onValue(dbRef, (snapshot) => {
                const data = snapshot.val();
                resolve(data);
            }, (error) => {
                reject(error);
            })
        })
    } catch (err) {
        console.log("Issue fetching snapshot of database", err);
        // throw err;
    }
}

export const signUpUser = async (email, password, firstName, lastName, location) => {
    try {
        const auth = getAuth();
        const db = getDatabase();
        return await new Promise((resolve, reject) => {
            createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
                const user = userCredential.user;
                // const actionCodeSettings = {
                //     url: `${location}/?email=${user.email}`
                // }
                // localStorage.setItem('token', user.accessToken);
                
                updateProfile(user, {displayName: `${firstName} ${lastName}`}).then(() => {
                    console.log("Profile updated");
                }).catch ((error) => {
                    console.log("Issue updating user's profile", error);
                })

                set(ref(db, `users/${user.uid}`), { firstName: firstName, lastName: lastName, email: email, party: "", role: "guest" }).then(() => {
                    console.log("User added to database");
                }).catch ((error) => {
                    console.log("Issue writing user data", error);
                })

                sendEmailVerification(user).then(() => {
                    console.log("Email verification sent");
                }).catch ((error) => {
                    console.log("Issue sending email verification", error);
                })

                resolve(user);
            }, (error) => {
                reject(error);
            })
        })
    } catch (err) {
        console.log("Issue creating user to database", err);
        return {"error": err.code};
    }
}

export const signInUser = async (email, password) => {
    try {
        const auth = getAuth();
        return await new Promise((resolve, reject) => {
            signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
                const user = userCredential.user;
                localStorage.setItem('token', user.accessToken);
                resolve(user);
            }, (error) => {
                reject(error);
            })
        })
    } catch (err) {
        console.log("Issue logging in user", err);
        return {"error": err.code};
    }
}

export const signOutUserAuth = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
        console.log("Sign out successful");
    }).catch ((error) => {
        console.log("Issue logging out user", error);
        // throw error;
    })
}

export const createInfo = async (dataObj) => {
    try {
        const auth = getAuth();
        const db = getDatabase();
        return await new Promise((resolve, reject) => {
            onAuthStateChanged(auth, (user) => {
                if(user) {
                    const dbRef = ref(db, `users/${user.uid}`);
                    onValue(dbRef, (snapshot) => {
                        if(snapshot.exists()) {
                            const userData = snapshot.val();
                            const partyName = (userData.lastName + userData.firstName).toLowerCase();
                            const dbUserRef = ref(db, `users/${user.uid}/party`);
                            const dbPartyRef = ref(db, `parties/${partyName}`);

                            onValue(dbPartyRef, (snapshot) => {
                                if(!snapshot.exists()) {
                                    set(dbPartyRef, dataObj).then(() => {
                                        console.log("Data added to database");
                                    }).catch ((error) => {
                                        console.log("Issue writing data", error);
                                    })

                                    set(dbUserRef, partyName).then(() => {
                                        console.log("User's party status updated");
                                    }).catch ((error) => {
                                        console.log("Issue updating user's party status", error);
                                    })

                                    console.log("Party data created");
                                }
                            })
                        }
                    })
                }
                resolve(user);
            }, (error) => {
                reject(error);
            })
        })
    } catch (err) {
        console.log("Issue creating data in database", err);
        // throw err;
    }
}

export const updateInfo = async (dataObjList) => {
    try {
        const auth = getAuth();
        const db = getDatabase();
        return await new Promise((resolve, reject) => {
            onAuthStateChanged(auth, (user) => {
                if(user) {
                    const dbRef = ref(db, `users/${user.uid}`);
                    onValue(dbRef, (snapshot) => {
                        if(snapshot.exists()) {
                            const userData = snapshot.val();
                            const dbPartyRef = ref(db, `parties/${userData.party}`);

                            set(dbPartyRef, dataObjList).then(() => {
                                console.log("Data updated in database");
                            }).catch((error) => {
                                console.log("Issue writing data", error);
                            })
                        }
                    })
                }
                resolve(user);
            }, (error) => {
                reject(error);
            })
        })
    } catch (err) {
        console.log("Issue updating data in database", err);
        // throw err;
    }
}