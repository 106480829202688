import React, { Component } from 'react';
import coverPhoto from '../images/official-engagement-photo.jpg';
import Navbar from './components/Navbar';
import Divider from './components/Divider';

class Home extends Component {
    state = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        weddingDate: new Date(this.props.weddingDate).getTime(),
        timeoutId: ""
    }

    componentDidMount() {
        const timeoutId = this.runCountdown();
        this.setState({
            timeoutId: timeoutId
        })
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeoutId);
    }

    runCountdown() {
        const now = new Date().getTime();
        const difference = this.state.weddingDate - now;
        if(difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);
            setTimeout(() => this.runCountdown(), 1000);
            this.setState({
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds
            })
        }
    }

    render() {
        const { homeData } = this.props;
        const { days, hours, minutes } = this.state;
        return (
            <div className="App">
                <Navbar showTitle={true}/>
                <img src={coverPhoto} className="img-center" alt="engagement in Kyoto"/>
                <p className="caption">Photo by <a href="https://www.reypaulweddings.com/" target="_blank" rel="noopener noreferrer">Rey Paul Weddings</a></p>
                <Divider/>
                <div className="App-details">
                    <p className="App-details-title">{homeData.date}</p>
                    <p className="App-details-header">{homeData.location}</p>
                    <p>{days} days {hours} hrs {minutes} min</p>
                    <p>{homeData.text}</p>
                </div>
            </div>
        );
    }
}

export default Home;